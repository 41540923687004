import './App.css';

const mainNav = [
  'FSE Agency',
  'Industry Insights',
  'Contact Us',
];

const brandExperienceList = [
  '20th Centrury Fox', 'AAA', 'ACLU', 'American Museum of Natural History', 'Ann Taylor', 'Blackstone', 'Carnegie Hall', 'Diesel', 'Edison Properties', 'Fast Company', 'Gap Inc.', 'Google',
  '20th Centrury Fox', 'AAA', 'ACLU', 'American Museum of Natural History', 'Ann Taylor', 'Blackstone', 'Carnegie Hall', 'Diesel', 'Edison Properties', 'Fast Company', 'Gap Inc.', 'Google',
  '20th Centrury Fox', 'AAA', 'ACLU', 'American Museum of Natural History', 'Ann Taylor', 'Blackstone', 'Carnegie Hall', 'Diesel', 'Edison Properties', 'Fast Company', 'Gap Inc.', 'Google',
];

const whyFSE = [
  'Clients as Partners',
  'Goal Driven Design',
  'Unapologetically Transparent',
  'Quality Over Quantity',
  'Technology Agnostic',
];

const socialLinks = [
  'Twitter',
  'Instagram',
  'LinkedIn',
  'Facebook',
];

const footerNav = [
  'Services',
  'Our Agency',
  'Industry Insights',
  'Contact Us',
];

const gallery = [
  'https://fpoimg.com/375x500',
  'https://fpoimg.com/375x500',
];

function App() {
  return (
    <div className="mx-8 my-8">
      <menu className="flex justify-between mb-32">
        <a href="/"><img src="https://fpoimg.com/60x60" width="60" height="60" /></a>
        <nav className="flex items-center">
          <ul className="flex">
            {mainNav.map(item => <li className="mr-4"><a href="#">{item}</a></li>)}
          </ul>
          <button><img src="https://fpoimg.com/40x40" width="40" height="40" /></button>
        </nav>
      </menu>

      <header>
        <div className="px-14">
          <h1 className="mb-6 text-sm text-gray-light tracking-widest uppercase">About FSE</h1>
          <p className="mb-16 text-7xl font-bold">
            <span className="inline text-blue-dark text-highlight-yellow">A team of planners, <br/>makers, & doers.</span>
          </p>
        </div>
        <picture>
          <img className="w-full" src="https://fpoimg.com/900x350" width="900" height="350"/>
        </picture>
        <div className="flex justify-center mt-20 mx-auto">
          <p className="px-48 text-2xl">We help startups & established innovators uncover and communicate their value to the world. Our veteran team of entrepreneurs, strategists, storytellers, visual designers and technologist have helped define and launch some of the worlds most inspired and disruptive brands.</p>
        </div>
      </header>

      <section className="mt-32 px-14 flex">
        <div className="pr-20">
          <h2 className="mb-6 text-sm text-gray-light tracking-widest uppercase">Work & Play</h2>
          <span className="text-7xl font-bold text-blue-dark text-highlight-yellow">Nash<br/>ville, TN</span>
        </div>
        <div>
          <div className="flex">
            {gallery.map(image => (
              <figure className="mr-6">
                <img src={image} width="375" height="500" />
              </figure>
            ))}
          </div>
          <div className="text-right mt-4">
            <button></button>
            <span className="text-sm">1<span class="mx-2">/</span>9</span>
            <button></button>
          </div>
        </div>
      </section>

      <section className="px-14 py-20 mt-20 bg-blue-dark">
        <h2 className="mb-6 text-yellow uppercase tracking-widest">Our Brand Experience</h2>
        <ul>
          { brandExperienceList.map(item => (
            <li className="inline-block pb-1 text-white text-2xl">
              <span>{item}</span>
              <span class="mx-3">/</span>
            </li>
          ))}
        </ul>
      </section>

      <section className="px-14 mt-20">
        <div className="pr-8 w-1/2">
          <h2 className="mb-6 text-sm text-gray-light tracking-widest uppercase">Why FSE</h2>
          <h3 className="text-3xl font-bold pr-40 mb-6">A few reasons why you should work with us.</h3>
          <p>Here are serveral client stories about successful product launches. Many of our other engagements are confidential. Contact us to learn more about what we can offer.</p>
          <span className="inline-block mt-8 w-14 h-2 bg-blue-bright"></span>
        </div>
        <div className="flex flex-wrap mt-14">
          { whyFSE.map(item => (
            <div className="flex-grow flex mb-8  w-1/2">
              <img src="https://fpoimg.com/100x100" width="100" height="100" />
              <div className="ml-4">
                <h4 className="mb-2 text-gray-light font-bold uppercase">{item}</h4>
                <p>TODO</p>
              </div>
            </div>
          ))}
          <div className="flex-grow w-1/2 text-center mt-8">
            <a href="#" className="px-10 py-4 bg-blue-bright text-white text-lg rounded-full shadow-xl hover:bg-blue-dark">Get a Quote</a>
          </div>
        </div>
      </section>

      <footer className="flex px-14 py-20 mt-20 bg-yellow">

        <div className="pr-20 w-1/2">
            <p className="text-3xl font-bold">Need help? <br/><span className="text-blue-bright">Let's Make something!</span></p>
            <div className="mt-6 bg-gray-200 w-full h-16">

            </div>
            <p className="mt-4 text-sm">&copy; From Somebody Else, LLC.</p>
        </div>

        <div className="flex-grow flex justify-between">
          <nav className="flex-grow">
            <ul>
              {footerNav.map(link => <li className="mb-5 text-sm"><a href="">{link}</a></li>)}
            </ul>
          </nav>
          <address className="flex-growm text-sm font-normal">
            <span className="block mb-5">Nashville, TN</span>
            <span className="block mb-5">954.608.2031</span>
            <span className="block mb-5">info@fseagency.com</span>
          </address>
          <div className="flex-grow-0">
            <div className="mb-6"><img src="https://fpoimg.com/30x30" width="30" height="30" /></div>
            {socialLinks.map(link => (
              <div className="mb-6">
                <a href="https://twitter.com">
                  <img src="https://fpoimg.com/30x30" width="30" height="30" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
